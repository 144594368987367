import { ConfigType } from './configType';

const config: ConfigType = {
  apiPath: 'https://fachtest.klinik-puls.de:443',
  jwt: {
    refreshInMilliSeconds: 360000
  },
  devMode: false
};

export default config;
