import { observable, action } from 'mobx';

import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { ContentType, ProcessingStatus } from 'dto/file';

export default class FlyoutStore {
  @observable
  imagesToView?: ContentElementMediaDTO[] | null = null;

  /**
   * The viewer now supports to display a list of images.
   * This index is the currently displayed one.
   */
  @observable
  selectedImageIndex: number | null = null;

  @observable
  pdfToView?: ContentElementMediaDTO | null = null;

  @observable
  isCreateSingleMaterialFlyoutOpen = false;

  @observable
  isCreateMaterialSetFlyoutOpen = false;

  @observable
  isHistoryFlyoutOpen = false;

  @observable
  isMoveStorageLocationFlyoutOpen = false;

  @observable
  showGuideItemDetailDrawer = false;

  @observable
  isDeleteMaterialSetFlyoutOpen = false;

  @observable
  guideItemInDrawerType?: 'material' | 'package';

  /**
   * Loads the image viewer dialog with the specified images.
   * @param images Can either be one image (ContentElementMediaDTO or url string) or an array of images.
   * @param selectedImageIndex The selectedImageIndex defines which image is selected first.
   * @returns
   */
  @action
  setImagesToView(images: string | ContentElementMediaDTO | (string | ContentElementMediaDTO)[] | null, selectedImageIndex = 0) {
    if (images === null) {
      this.selectedImageIndex = null;
      this.imagesToView = null;
      return;
    }

    this.selectedImageIndex = selectedImageIndex;

    let imageList: (string | ContentElementMediaDTO)[];
    if (!Array.isArray(images)) {
      imageList = [images];
    } else {
      imageList = images;
    }

    this.imagesToView = imageList.map(
      (image): ContentElementMediaDTO => {
        if (typeof image === 'string') {
          return {
            contentType: ContentType.Picture,
            status: ProcessingStatus.Finished,
            fileId: 'null',
            fullUrl: image,
            originalFileName: ''
          };
        }
        return image;
      }
    );
  }

  @action
  nextImage() {
    if (this.selectedImageIndex === null || this.imagesToView == null || this.imagesToView.length <= 0) {
      return;
    }
    this.selectedImageIndex = (this.selectedImageIndex + 1) % this.imagesToView.length;
  }

  @action
  previousImage() {
    if (this.selectedImageIndex === null || this.imagesToView == null || this.imagesToView.length <= 0) {
      return;
    }

    let newIndex = this.selectedImageIndex - 1;
    if (newIndex < 0) {
      newIndex = this.imagesToView.length - 1;
    }

    this.selectedImageIndex = newIndex;
  }

  @action
  setPdfToView = (pdfToView: ContentElementMediaDTO | null) => {
    this.pdfToView = pdfToView;
  };

  @action
  setIsCreateSingleMaterialFlyoutOpen = (isCreateSingleMaterialFlyoutOpen: boolean) => {
    this.isCreateSingleMaterialFlyoutOpen = isCreateSingleMaterialFlyoutOpen;
  };

  @action
  setIsCreateMaterialSetFlyoutOpen = (isCreateMaterialSetFlyoutOpen: boolean) => {
    this.isCreateMaterialSetFlyoutOpen = isCreateMaterialSetFlyoutOpen;
  };

  @action
  setIsHistoryFlyoutOpen = (isHistoryFlyoutOpen: boolean) => {
    this.isHistoryFlyoutOpen = isHistoryFlyoutOpen;
  };

  @action
  setIsMoveStorageLocationFlyoutOpen = (isMoveStorageLocationFlyoutOpen: boolean) => {
    this.isMoveStorageLocationFlyoutOpen = isMoveStorageLocationFlyoutOpen;
  };

  @action
  setIsDeleteMaterialSetFlyoutOpen(status: boolean) {
    this.isDeleteMaterialSetFlyoutOpen = status;
  }

  @action
  setShowItemDetailDrawer(showGuideItemDetailDrawer: boolean) {
    this.showGuideItemDetailDrawer = showGuideItemDetailDrawer;
    if (!showGuideItemDetailDrawer) {
      this.guideItemInDrawerType = undefined;
    }
  }
}
