import React from 'react';
import Picture from 'components/Picture';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import { useTranslation } from 'react-i18next';
import { useStores } from 'util/mobx/stores';
import { OptionalLazyLoadProps } from 'components/OptionalLazyLoad/OptionalLazyLoad';

interface Props extends OptionalLazyLoadProps {
  allFiles: ContentElementMediaDTO[];
  file: ContentElementMediaDTO;
  inDrawer?: boolean;
  noPreview?: boolean;
}

export default ({ allFiles, file, inDrawer, noPreview, lazyLoadScrollContainer }: Props) => {
  const { flyoutStore } = useStores();
  const { t } = useTranslation('itemFormFlyOut');

  if (inDrawer) {
    return (
      <div
        className="image_post_wide in_board"
        onClick={() =>
          !noPreview &&
          flyoutStore.setImagesToView(
            allFiles,
            allFiles.findIndex(allFilesFile => allFilesFile.fileId === file.fileId)
          )
        }
      >
        <Picture src={file.smallUrl} width="344" alt="" lazyLoadScrollContainer={lazyLoadScrollContainer} className="chat_image" />
      </div>
    );
  }

  return (
    <div className="post_image">
      <div
        className="image_post_wide"
        onClick={() =>
          !noPreview &&
          flyoutStore.setImagesToView(
            allFiles,
            allFiles.findIndex(allFilesFile => allFilesFile.fileId === file.fileId)
          )
        }
      >
        {file.pictureLabelling && (
          <div className="info_box_image">
            <div>{t('uploadFiles.imageMarking')}</div>
          </div>
        )}
        <Picture src={file.smallUrl} width="344" alt="" lazyLoadScrollContainer={lazyLoadScrollContainer} />
      </div>
      <div className="post_image_info">
        <div className="txt_post_image">{file.title}</div>
      </div>
    </div>
  );
};
