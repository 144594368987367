import React from 'react';
import { useTranslation } from 'react-i18next';
import { PostDTO } from 'dto/post';
import { useStores } from 'util/mobx/stores';
import { getDayTimeWording } from 'util/date';
import ImageCircle from 'components/ImageCircle';
import CommentFileBox from 'components/ThumbnailList/CommentFileBox';
import './DialogPost.css';
import Popover from 'components/Popover';
import ItemMenu from 'components/ItemMenu';

interface Props {
  post: PostDTO;
  menu: JSX.Element | JSX.Element[] | false;
}

const DialogPost = ({ post, menu }: Props) => {
  const { authStore } = useStores();
  const isUsersPost = post?.contentElement.user.userId === authStore.user.userId;
  const userClassName = isUsersPost ? 'user_comment' : '';
  return (
    <div className="board_chat_entry">
      {!isUsersPost && (
        <ImageCircle highlight={false} size="40" className="in_chat_bubble" user={post?.contentElement.user} withUserInfoBoxRight />
      )}
      <div className={`entry ${userClassName}`}>
        <div className={`t2 bottom-margin ${userClassName}`}>
          {post?.contentElement.user.firstName} {post?.contentElement.user.lastName}
        </div>
        <div className={`b_chat_content ${userClassName}`} style={{ position: 'relative' }}>
          <div className={`txt_message show-newlines ${userClassName}`}>{post?.contentElement.description}</div>
          {menu && (
            <div className="btn_show_detail_post board-edit-button">
              <Popover trigger={<img src="images/icon_menu_dots.svg" alt="" className="image_dots_post image-dots-post" />}>
                {({ handleClose, isOpen }) => (
                  <ItemMenu handleClose={handleClose} isOpen={!!isOpen} className="board-menu">
                    {menu}
                  </ItemMenu>
                )}
              </Popover>
            </div>
          )}
          <div className="dialog-post-files">
            {post?.contentElement.contentElementMedias.map(contentElementMedia => (
              <CommentFileBox
                key={contentElementMedia.fileId}
                file={contentElementMedia}
                files={post.contentElement.contentElementMedias}
                inBoard
              />
            ))}
          </div>
          <div className="t2 bottom-margin">{getDayTimeWording(post.createdAt, useTranslation('date'), true)}</div>
        </div>
      </div>
      {isUsersPost && <ImageCircle highlight={false} size="40" className="user_comment" user={post?.contentElement.user} />}
    </div>
  );
};

export default DialogPost;
