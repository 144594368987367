import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useStores } from 'util/mobx/stores';
import { useTranslation } from 'react-i18next';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';

import './FullScreenImageFlyout.css';
import { ContentElementMediaDTO } from 'dto/contentElementMedia';
import FlyoutContainer from 'components/FlyoutContainer';
import Button from 'components/Form/Button';
import { useSecureURL } from 'util/hooks';

interface ImageProps {
  width: number;
  height: number;
}

interface ZoomProps {
  zoomIn: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  zoomOut: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  resetTransform: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const FullScreenImageFlyout = observer(() => {
  const { flyoutStore } = useStores();
  const { t } = useTranslation('imageFlyout');
  const [isLabelsShown, setIsLabelsShown] = useState<boolean>(true);

  const getImageUrl = (file: ContentElementMediaDTO) => {
    if (!isLabelsShown) {
      return file.fullUrl;
    }
    return file.pictureLabellingUrl || file.fullUrl;
  };

  const imageURL = useSecureURL(
    flyoutStore.imagesToView ? getImageUrl(flyoutStore.imagesToView[flyoutStore.selectedImageIndex || 0]) : undefined
  );

  const closePopUp = () => flyoutStore.setImagesToView(null);

  const next = () => {
    flyoutStore.nextImage();
  };

  const previous = () => {
    flyoutStore.previousImage();
  };

  return (
    <FlyoutContainer
      icon={<img src="images/icon_edit.jpg" width="50" alt="" className="image_circle_40" />}
      isOpen={!!flyoutStore.imagesToView}
      className="fullscreen-flyout"
      closePopUp={closePopUp}
      cancelLabel={t('cancel')}
      closeAsSubmit
      title={t('imageViewerTitle')}
      isAllowedToSubmit
    >
      <div className="fullscreen-content">
        <div className="image-flyout-content">
          <TransformWrapper options={{ limitToWrapper: false, minScale: 0 }} defaultPositionX={0} defaultPositionY={0}>
            {({ zoomIn, zoomOut, resetTransform }: ZoomProps) => {
              if (!flyoutStore.imagesToView) {
                return null;
              }
              const onLoad = () => {
                resetTransform();
              };
              return (
                <div className="image-viewer-container">
                  {flyoutStore.imagesToView && flyoutStore.imagesToView.length > 1 ? (
                    <div className="image-viewer-nav-button-container">
                      <Button
                        icon="images/icon_left-arrow_16_white.svg"
                        type="button"
                        className="btn-transparent-round"
                        onClick={previous}
                      />
                    </div>
                  ) : null}
                  <div className="image-viewer-picture-container">
                    <TransformComponent>
                      <img
                        src={imageURL}
                        onLoad={onLoad}
                        alt={flyoutStore.imagesToView[flyoutStore.selectedImageIndex || 0].title || ''}
                        className="image-to-zoom"
                      />
                    </TransformComponent>
                    <div className="tools">
                      <Button icon="images/zoom-in.svg" type="button" className="btn-transparent-round" onClick={zoomIn} />
                      <Button icon="images/zoom-out.svg" type="button" className="btn-transparent-round" onClick={zoomOut} />
                      <Button icon="images/reset.svg" type="button" className="btn-transparent-round" onClick={resetTransform} />

                      {flyoutStore.imagesToView[flyoutStore.selectedImageIndex || 0]?.pictureLabellingUrl && (
                        <Button
                          className="btn-transparent-round toggle-labels"
                          icon={isLabelsShown ? 'images/eye-hide.svg' : 'images/eye-show.svg'}
                          type="button"
                          onClick={() => setIsLabelsShown(!isLabelsShown)}
                        />
                      )}
                    </div>
                  </div>
                  {flyoutStore.imagesToView && flyoutStore.imagesToView.length > 1 ? (
                    <div className="image-viewer-nav-button-container">
                      <Button icon="images/icon_right-arrow_16_white.svg" type="button" className="btn-transparent-round" onClick={next} />
                    </div>
                  ) : null}
                </div>
              );
            }}
          </TransformWrapper>
        </div>
      </div>
    </FlyoutContainer>
  );
});

export default FullScreenImageFlyout;
